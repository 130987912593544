import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { useMedia } from '@src/lib/hooks';

import Contact from '../../components/contact';
import Hero from '../../components/hero';

import LanaContainer from './lanaContainer';
import Layout from '@components/layout';

import * as styles from './befintligt-lan.module.css';

export default function LoanExistsPage({ data }) {
  const pageContent =
    data.contentfulPage.pageContent[0].bodyText.childMarkdownRemark.html;
  const title = data.contentfulPage.headLine;

  const isMobile = useMedia(['(max-width: 896px)'], [true], false);
  return (
    <Layout path="/lana/befintligt-lan" newStyle>
      <Hero title={title} breadcrumbs={[{ name: 'Hem', path: '/' }]} />
      <LanaContainer padded={isMobile}>
        <div
          className={styles.loan_exist}
          dangerouslySetInnerHTML={{ __html: pageContent }}
        ></div>
      </LanaContainer>
      <Contact isLargeContact={true} hideFAQ />
    </Layout>
  );
}
LoanExistsPage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query LoanExistsPageQuery {
    contentfulPage(pageUrl: { eq: "/lana/befintligt-lan" }) {
      pageContent {
        ... on ContentfulDescriptionComponent {
          id
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      headLine
    }
  }
`;
